import React, { Component } from "react";

import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Main from "../components/Main";
import { Link } from "gatsby";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hire: "me",
      timeout: false,
      loading: "is-loading"
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: "" });
    }, 100);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div
          className={`body ${this.state.loading} ${
            this.state.isArticleVisible ? "is-article-visible" : ""
          }`}
        >
          <div id="wrapper">
            <header
              id="header"
              style={this.state.timeout ? { display: "none" } : {}}
            >
              <div className="content">
                <div className="inner">
                  <h3>Thank you!</h3>
                  <h1>I will get back to you very shortly</h1>

                  <p>
                    Need an advice? <br />Drop an email
                    <br />
                    <a href="mailto: abc@example.com">
                      borneikaaivaras@gmail.com
                    </a>
                  </p>
                  <p><Link to="/">GO BACK TO MAIN PAGE</Link></p>
                </div>
              </div>
            </header>
            {/* <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
            /> */}
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    );
  }
}

export default Success;
